import React from "react";
import { graphql } from "gatsby";
import { gql } from "@apollo/client";

import { unwrap } from "../components/WagtailWrapper";
import { RichText } from "../components/RichText";
import { Content, TightContent, Section, Frame } from "../components/Layouts";

import { Title, Prose } from "../components/Prose";
import { Primary } from "../components/Buttons";
import { Breadcrumbs } from "../components/SEOTags";
import { FoundationImage } from "../components/Images";
import { RelatedContent } from "../components/RelatedContent";

export const Component = ({ page }) => (
  <Frame
    page={page}
    url={`research-partnerships/${page?.slug}`}
    title={page?.title}
  >
    <Section>
      <Content>
        <header>
          <Breadcrumbs
            url={"research-partnerships"}
            list={"Research Partnerships"}
            title={page?.title}
          />
          <Title>{page?.title}</Title>
        </header>
      </Content>
    </Section>
    <div className="bg-gray-100">
      <div className="relative">
        <div className="absolute inset-0 h-1/2 bg-white"></div>
        <div className="relative max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden">
            <div className="bg-white relative w-full h-64">
              <div className="flex justify-center items-center h-full w-full px-12">
                <FoundationImage image={page?.image} height={200} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Section theme="dark" shadow={false}>
      {page?.link && (
        <div className="flex space-x-6 justify-center">
          {page?.link && (
            <Primary variant="small" url={page?.link}>
              Learn more
            </Primary>
          )}
        </div>
      )}

      {page?.description && (
        <TightContent>
          <Prose>
            {page?.description && (
              <>
                <h2>About</h2>
                <RichText html={page?.description} />
              </>
            )}
          </Prose>
        </TightContent>
      )}
      <RelatedContent page={page} />
    </Section>
  </Frame>
);

export const previewQuery = gql`
  query previewResearchPartnershipPage($contentType: String!, $token: String!) {
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoTitle
      seoDescription
      ... on ResearchPartnershipPage {
        link
        description
        image: logo {
          title
          src
        }
        relatedScientificProjects {
          relatedScientificProject {
            ...ExperimentCardFragment
          }
        }
        relatedPresentations {
          relatedPresentation {
            ...PresentationCardFragment
          }
        }
        relatedPublications {
          relatedPublication {
            ...PublicationCardFragment
          }
        }
      }
    }
  }
`;

export const query = graphql`
  query ResearchPartnershipPage($id: Int) {
    wagtail {
      page(id: $id) {
        id
        slug
        title
        seoTitle
        seoDescription
        ... on Wagtail_ResearchPartnershipPage {
          link
          description
          image: logo {
            title
            src
          }
          relatedScientificProjects {
            relatedScientificProject {
              ...Wagtail_ExperimentCardFragment
            }
          }
          relatedPresentations {
            relatedPresentation {
              ...Wagtail_PresentationCardFragment
            }
          }
          relatedPublications {
            relatedPublication {
              ...Wagtail_PublicationCardFragment
            }
          }
        }
      }
    }
  }
`;

export default unwrap(Component);
